<template>
  <div class="d-flex">
    <header-beamer-button v-if="showBeamer" />
    <header-help-center-button />
    <header-group-dropdown
      :is-sys-admin="isSysAdmin"
      :user-id="userId"
      :group-id="groupId"
    />
  </div>
</template>

<script>
import GetGroupPrivilegesByGroupId from '@/graphQlQueries/queries/getGroupPrivilegesByGroupId';

export default {
  name: 'HeaderFolderButtons',
  components: {
    HeaderHelpCenterButton: () => import('@/components/header/Buttons/HeaderHelpCenterButton.vue'),
    HeaderBeamerButton: () => import('@/components/header/Buttons/HeaderBeamerButton.vue'),
    HeaderGroupDropdown: () => import('@/components/header/Buttons/HeaderGroupDropdown.vue'),
  },
  props: {
    userId: { type: Number },
    isSysAdmin: { type: Boolean },
    groupId: { type: Number },
  },
  data() {
    return {
      showBeamer: false,
    };
  },
  apollo: {
    getGroupPrivileges: {
      query: GetGroupPrivilegesByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        const disableBeamerPriv = this.findGroupPrivilegeValue('disable_beamer', data);
        if (disableBeamerPriv !== true) {
          this.showBeamer = true;
        }
      },
    },
  },
  methods: {
    findGroupPrivilegeValue(privilegeName, data) {
      if (data.groups_by_pk) {
        const privObject = data.groups_by_pk.groups_privileges.find(
          (groupPriv) => groupPriv.privilege.handle === privilegeName,
        );
        if (privObject) {
          return privObject.value;
        }
      }
      // if no result was found use the default data
      const allGroupPrivileges = data.privileges;
      const groupPrivilege = allGroupPrivileges.find(
        (groupPriv) => groupPriv.handle === privilegeName,
      );
      if (groupPrivilege) {
        return groupPrivilege.default_value;
      }
      return null;
    },
  },
};
</script>
