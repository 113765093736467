var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _vm.showBeamer ? _c("header-beamer-button") : _vm._e(),
      _c("header-help-center-button"),
      _c("header-group-dropdown", {
        attrs: {
          "is-sys-admin": _vm.isSysAdmin,
          "user-id": _vm.userId,
          "group-id": _vm.groupId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }