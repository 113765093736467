import gql from 'graphql-tag';

export default gql`
query getGroupPrivilegesByGroupId($groupId: Int!) {
  groups_by_pk(group_id: $groupId) {
    name
    groups_privileges {
      value
      privilege {
        handle
      }
    }
  }
  privileges(where: {scope: {_eq: "groups"}}) {
    handle
    privilege_id
    default_value
  }
}
`;
